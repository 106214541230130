import React, { useEffect } from 'react';
import { Dialog, DialogTitle, Grid, Divider } from '@mui/material';
import Button from '@components/Button/Button';
import { Box, margin, padding, textAlign } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { copyToClipboard } from '@helpers/dom_manipulation';
import { useDispatch } from 'react-redux';
import { setCustomAlert, setInkPointModalOpen } from '@redux/actions/general';
import { makeStyles } from '@mui/styles';
import { styled } from '@mui/material/styles';
import { IsMobileWidth, IsSmallHeight } from '@components/util/MediaQueryUtils';
import { useTheme } from 'next-themes';
import Avatar from '@components/Avatar/Avatar';
import { useRouter } from 'next/router';
import Image from 'next/image';
import moment from 'moment';

const WrapperDialog = styled('div')`
  .paperRoot {
    overflow: inherit !important;
  }
`;
function ContentDialog(props) {
  const {
    // maxWidth,
    loggedUser,
    selectedAvatarOption,
    onShareClick,
    onClose,
    dialogIsOpen,
    planName = 'INK FREE',
    feature,
  } = props;

  const { theme } = useTheme();
  const dispatch = useDispatch();
  const matches = IsSmallHeight();
  const mobileWidth = IsMobileWidth();
  const router = useRouter();
  const content = {
    'AI-Tools': {
      name: 'INK free',
      desc: 'Generate AI content without interruptions',
      benefits: [
        'Write 10x faster',
        'Ask INK to write anything for you',
        'Experience INK’s NLO technology',
      ],
    },
    'Imagery-Generator': {
      name: 'Trial',
      desc: 'create the perfect visuals to go along with your content',
      benefits: [
        'Create visuals 10x faster',
        'Enhance results with filters',
        'Use INK AI Images anywhere',
      ],
    },
    'Keyword-Research': {
      name: 'Trial',
      desc: 'Make your content get found, engage, and convert',
      benefits: [
        'Save hours on keyword research',
        'Increase your traffic',
        'Increase your CTR',
      ],
    },
  };
  const handleNavigation = () => {
    onClose();
    router.push('/plans');
  };
  const useStyles = makeStyles({
    borderContent: {
      border: '1px solid #EBEBEF',
      height: '100%',
      textAlign: 'center',
      padding: '10px 10px',
      fontWeight: 'bold',
    },
    pinkBtn: {
      backgroundColor: '#e45c96',
      color: 'white',
      textTransform: 'capitalize',
      fontWeight: '600',
      fontFamily: 'Montserrat, sans-serif',
      padding: '10px 25px',
      '&:hover': {
        backgroundColor: '#cd5387',
      },
    },
    copyButton: {
      backgroundColor: '#2499e7',
      color: 'white',
      fontFamily: ' Montserrat, sans-serif',
      textTransform: 'capitalize',
      fontWeight: '600',
      padding: '10px',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: ' 0px',
      minWidth: '0px',
      '&:hover': {
        backgroundColor: '#208ad0',
      },
    },
    paper: {
      overflowY: !mobileWidth && matches ? 'inherit' : 'scroll',
      margin: mobileWidth ? '0px' : '32px',
      maxHeight: mobileWidth ? 'fit-content' : 'calc(100% - 64px);',
      height: mobileWidth ? '100vh' : '',
      width: mobileWidth ? '100vh' : '390px',
      backgroundColor: theme === 'dark' ? '#071116' : '',
    },
    borderAvatar: {
      border: theme === 'light' ? '2px solid #EBEBEF' : '2px solid #1E2628',
    },
    darkBorderAvatar: {
      border: '2px solid #1E2628',
    },
  });
  const classes = useStyles();
  useEffect(() => {}, [matches]);

  const handleNavigationToPlan = () => {
    dispatch(setInkPointModalOpen());
    router.push('/plans');
  };

  const planTitleStyling = {
    backgroundColor: 'rgba(228, 92, 150, 0.1)',
    borderRadius: '3px',
    fontFamily: 'Montserrat',
    fontSize: '14px',
    fontWeight: '600',
    color: '#E45C96',
    textAlign: 'center',
    padding: '4px 8px',
  };
  const contentTextStyling = {
    fontFamily: 'Montserrat',
    fontSize: '16px',
    textTransform: 'capitalize',
    // color: '#505876',
    fontWeight: '600',
    textAlign: 'center',
    lineHeight: '22px',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '15px',
  };
  const benefitsStyling = {
    fontWeight: '400',
    display: 'flex',
    lineHeight: '30px',
    columnGap: '10px',
  };
  return (
    <WrapperDialog>
      <Dialog
        open={dialogIsOpen}
        onClose={onClose}
        maxWidth={!mobileWidth ? 'xs' : 'xl'}
        fullWidth
        classes={{ paper: classes.paper }}
      >
        <div className='flex flex-col'>
          <DialogTitle className='flex justify-between items-center'>
            <div className='w-[85px] h-[30px]' style={planTitleStyling}>
              {planName}
            </div>
            <CloseIcon
              onClick={onClose}
              style={{ color: theme === 'dark' ? 'white' : '#505876' }}
              className='cursor-pointer'
            />
          </DialogTitle>
        </div>
        <div className='flex flex-col items-center '>
          <div>
            <Image
              width='140px'
              height='114px'
              src='/assets/upgrade-plan.png'
              alt='upgrade-plan'
            />
          </div>
          <div
            className='sm:w-[95%] md:w-[85%] lg:w-[w-80%] dark:text-white text-custom-font-color'
            style={contentTextStyling}
          >
            {content?.[feature]?.desc}

            <div className='mt-[20px]'>
              {content?.[feature]?.benefits.map((benefit, idx) => (
                <p key={idx} style={benefitsStyling}>
                  <img src='/assets/right-mark.svg' alt='right-mark' />
                  {benefit}
                </p>
              ))}
            </div>
          </div>
          <div className='mt-[20px] mb-[15px]'>
            <Button
              id='button-navigate-plans'
              className='gradient-btn'
              onClick={handleNavigation}
            >
              Upgrade Now
            </Button>
          </div>
        </div>

        {/* <div
          id='content-dialog relative'
          className='pb-4 text-custom-font-color dark:bg-header-black'
        >
          {!mobileWidth && matches ? (
            <div className='absolute z-2' style={{ left: '42%', top: '-40px' }}>
              {
                // selectedAvatarOption &&
                selectedAvatarOption?.accountType === 'personal-team' ? (
                  <Avatar
                    style={{ height: 80, width: 80 }}
                    title={selectedAvatarOption?.title}
                    alt='team'
                  />
                ) : selectedAvatarOption?.picture ? (
                  <Avatar
                    style={{ height: 80, width: 80 }}
                    picture={selectedAvatarOption?.picture}
                    alt='user'
                  />
                ) : (
                  <Avatar
                    style={{ height: 80, width: 80 }}
                    title={selectedAvatarOption?.title}
                    alt='team'
                  />
                )
              }
            </div>
          ) : null}
          <DialogTitle>
            <div className='flex w-full justify-end text-right '>
              <CloseIcon onClick={onClose} className='cursor-pointer' />
            </div>
          </DialogTitle>
          {!matches ? (
            <div className='flex justify-center pb-3'>
              {selectedAvatarOption?.accountType === 'personal-team' ? (
                <Avatar
                  style={{ height: 60, width: 60 }}
                  title={selectedAvatarOption?.title}
                  alt='team'
                />
              ) : selectedAvatarOption?.picture ? (
                <Avatar
                  style={{ height: 60, width: 60 }}
                  picture={selectedAvatarOption?.picture}
                  alt='user'
                />
              ) : (
                <Avatar
                  style={{ height: 60, width: 60 }}
                  title={selectedAvatarOption?.title}
                  alt='team'
                />
              )}
            </div>
          ) : null}

          <div className='content px-4'>
            <div className='font-weight-custom dark:text-white text-center capitalize text-xl'>
              <b className='name '> {loggedUser?.name}</b>
            </div>
            {selectedAvatarOption?.accountType === 'personal' &&
            loggedUser?.upcomingInvoice?.nextInvoiceDate ? (
              <>
                <div className='text-center text-sm pt-2 dark:text-white'>
                  Your AI Word credits will replenish on{' '}
                  <strong>
                    {moment(
                      loggedUser?.upcomingInvoice?.nextInvoiceDate * 1000
                    ).format('MMMM DD, YYYY')}
                  </strong>
                  .
                </div>
              </>
            ) : null}
            <Grid container className='pt-3' spacing={2}>
              <Grid item xs={6}>
                <Box
                  border='1px solid #EBEBEF'
                  className='rounded-md font-bold  h-full p-3 dark:border-1 dark:border-custom-border'
                >
                  <div className='text-xs dark:text-white text-center text-custom-font-color'>
                    Plan
                  </div>
                  <div className='text-primary-pink text-center text-xl pt-2'>
                    {selectedAvatarOption?.userPlan?.name}
                  </div>
                </Box>
              </Grid>

              <Grid item xs={6}>
                <Box
                  border='1px solid #EBEBEF'
                  className='rounded-md font-bold  h-full p-3 dark:border-1 dark:border-custom-border'
                >
                  <div className='text-xs text-center dark:text-white text-custom-font-color'>
                    AI WORDS
                  </div>
                  <div className='text-secondary-blue flex items-center justify-center text-xl pt-2'>
                    {selectedAvatarOption?.wallet?.words?.unlimited
                      ? 'UNLIMITED'
                      : selectedAvatarOption?.wallet?.words?.amount}

                    <img
                      className='ml-1'
                      alt='img'
                      width='20px'
                      src='/assets/share-ink-photo.svg'
                    />
                  </div>
                </Box>
              </Grid>
            </Grid>
            {selectedAvatarOption?.accountType === 'personal' ? (
              <>
                <div className='text-center text-sm pt-5'>
                  <div className='dark:text-white'>
                    Unlimited AI Writing for as little as $20/mo on our annual
                    plans.
                  </div>
                </div>
                <div className='text-center pt-2'>
                  <Button
                    onClick={handleNavigationToPlan}
                    className={classes.pinkBtn}
                  >
                    Check Plans
                  </Button>
                </div>
                <div className='pt-5 pb-3'>
                  <Divider />
                </div>
              </>
            ) : (
              <div className='pb-3' />
            )}
            <div className='pb-4'>
              <div className='text-center dark:text-white font-bold '>
                {selectedAvatarOption?.accountType !== 'personal' ? (
                  <span>Share INK with Friends!</span>
                ) : (
                  <div>
                    <span className='block'>Share INK with Friends to get</span>
                    <span className='text-secondary-blue px-1 flex gap-2 justify-center'>
                      +5,000
                      <img
                        alt='img'
                        width='20px'
                        src='/assets/share-ink-photo.svg'
                      />
                    </span>
                  </div>
                )}
              </div>
              <div className='text-center pt-2 text-sm dark:text-white'>
                {selectedAvatarOption?.accountType !== 'personal'
                  ? 'Look like a hero to a friend in need by sharing your URL'
                  : 'Give and get free AI Words when someone Signs Up using your INK URL.'}
              </div>
            </div>
            <div>
              <Box
                className='w-100 flex  justify-between items-center bg-gray-100	dark:bg-header-black dark:border-1 dark:border-custom-border rounded-md'
                border='1px solid #EBEBEF'
              >
                <a className='pl-3 text-sm truncate dark:text-white'>
                  {loggedUser?.referralCode}
                </a>
                <Button
                  onClick={() =>
                    copyToClipboard(
                      loggedUser?.referralCode,
                      dispatch,
                      setCustomAlert
                    )
                  }
                  className={classes.copyButton}
                >
                  <Image
                    width='24px'
                    height='24px'
                    alt='copy'
                    src='/assets/copy.svg'
                  />
                </Button>
              </Box>
            </div>
            <div className='social-icons pt-4 flex items-center justify-center'>
              <div
                className='px-2'
                onClick={() =>
                  onShareClick('twitter', loggedUser?.referralCode)
                }
              >
                <img
                  className='cursor-pointer'
                  src='/assets/twitter.png'
                  alt='twitter'
                  width={40}
                  height={40}
                />
              </div>
              <div
                className='px-2'
                onClick={() =>
                  onShareClick('facebook', loggedUser?.referralCode)
                }
              >
                <img
                  className='cursor-pointer'
                  src='/assets/facebook.png'
                  alt='facebook'
                  width={40}
                  height={40}
                />
              </div>
              <div
                className='px-2'
                onClick={() =>
                  onShareClick('linkedin', loggedUser?.referralCode)
                }
              >
                <img
                  className='cursor-pointer'
                  src='/assets/linkedin.png'
                  alt='linkedin'
                  width={40}
                  height={40}
                />
              </div>
            </div>
          </div>
        </div> */}
      </Dialog>
    </WrapperDialog>
  );
}
ContentDialog.propTypes = {
  // maxWidth: PropTypes.string,
  loggedUser: PropTypes.object,
  selectedAvatarOption: PropTypes.object,
  onShareClick: PropTypes.func,
  onClose: PropTypes.func,
  dialogIsOpen: PropTypes.bool,
  planName: PropTypes.string,
  feature: PropTypes.string,
};
export default ContentDialog;
