/* eslint-disable react/prop-types */

import { Typography } from '@mui/material';
import React from 'react';

const GradientText = ({ variant = 'p', children, ...props }) => (
  <Typography variant={variant} className='gradient-text' {...props}>
    {children}
  </Typography>
);

export default GradientText;
