import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from 'next-themes';

const CustomSkeleton = ({
  style = {},
  variant = 'circular',
  width = 40,
  height = 40,
}) => {
  const { theme } = useTheme();

  /*
  ? use suppressHydrationWarning to avoid this warning due to sx prop:
    Warning: Prop `className` did not match. Server: "MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse css-13ivzke-MuiSkeleton-root" Client: "MuiSkeleton-root MuiSkeleton-rectangular MuiSkeleton-pulse css-rsom5l-MuiSkeleton-root"

    * We have the difference in className because of 'backgroundColor' in sx prop
  */

  return (
    <Skeleton
      sx={{ ...style, backgroundColor: theme === 'dark' && '#f3f3f3' }}
      variant={variant}
      width={width}
      height={height}
      suppressHydrationWarning
    />
  );
};

CustomSkeleton.propTypes = {
  variant: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
};

export default CustomSkeleton;
