import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { Grid } from '@mui/material';
import Image from 'next/image';
import HomeIcon from '@mui/icons-material/Home';
import Drawer from '@mui/material/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { styled } from '@mui/material/styles';
import { useTheme } from 'next-themes';
import dynamic from 'next/dynamic';

const ItemsList = dynamic(
  () => import('@library/components/Molecules/Sidebar/items.list'),
  {
    ssr: false,
  }
);

const useStyles = makeStyles({
  root: {
    width: '100%',
    fontFamily: `'Montserrat', sans-serif`,
  },
});
const MobileWrapper = styled('div')`
  font-family: 'Montserrat', sans-serif !important;
  .MuiTypography-root {
    font-family: 'Montserrat', sans-serif !important;
  }
`;

export function AiIcon() {
  const router = useRouter();
  const { theme } = useTheme();

  return (
    <div className='flex justify-center'>
      <img
        alt=''
        src={
          router.asPath === '/tools'
            ? '/assets/blue.tool.svg'
            : theme === 'dark'
            ? '/assets/white.ai.svg'
            : '/assets/ai.tools.svg'
        }
        width='22px'
      />
    </div>
  );
}
export default function MobileDrawer() {
  const [mobileDrawerOpened, setMobileDrawerOpened] = React.useState(false);

  const { theme } = useTheme();
  const router = useRouter();
  const classes = useStyles();

  const toggleDrawer = () => {
    console.log('toggleDrawer');
    setMobileDrawerOpened((prev) => !prev);
  };
  return (
    <MobileWrapper>
      <button onClick={toggleDrawer} type='button'>
        <MenuIcon className='cursor-pointer text-custom-font-color dark:text-white' />
        <Typography
          fontWeight='bold'
          className='cursor-pointer text-custom-font-color  dark:text-white'
        >
          Menu
        </Typography>
      </button>
      <Drawer
        style={{ width: '100% !important' }}
        className='w-full'
        anchor='left'
        open={mobileDrawerOpened}
        onClose={toggleDrawer}
        classes={{
          root: classes.root,
          paper: classes.root,
        }}
        sx={{ border: 'none' }}
      >
        <div className='pt-3 pl-3 dark:bg-header-black'>
          <Image
            layout='fixed'
            width='45px'
            height='45px'
            src='/assets/ink.svg'
          />
        </div>
        <ItemsList mobileDrawerOpened={mobileDrawerOpened} />
        <Box
          className='w-full bg-white text-black dark:bg-header-black dark:text-white  dark:border-custom-border'
          height='60px'
          borderTop='2px solid #EBEBEF'
        >
          <Grid container alignItems='center' className=' px-1 pt-2 '>
            <Grid xs={4} className='text-center' onClick={toggleDrawer}>
              <CloseIcon className='text-custom-font-color cursor-pointer  dark:text-white' />
              <div className='cursor-pointer font-bold text-custom-font-color  dark:text-white'>
                Menu
              </div>
            </Grid>

            <Link href='/hey-ink'>
              <Grid xs={4} className='text-center cursor-pointer'>
                <div className='flex justify-center'>
                  <img
                    className='cursor-pointer'
                    alt='tools'
                    src={
                      router.asPath.includes('/hey-ink')
                        ? '/assets/heyink-blue.svg'
                        : theme === 'dark'
                        ? '/assets/heyink-white.svg'
                        : '/assets/heyink-black.svg'
                    }
                    width={24}
                    height={20}
                  />
                </div>
                <Typography
                  className={
                    router.asPath === '/hey-ink'
                      ? 'text-secondary-blue  pt-1'
                      : 'dark:text-white cursor-pointer text-custom-font-color  pt-1'
                  }
                  fontWeight='bold'
                >
                  AI Assistant
                </Typography>
              </Grid>
            </Link>
            <Link href='/image-generator'>
              <Grid xs={4} className='text-center cursor-pointer'>
                <div className='flex justify-center'>
                  <img
                    alt='tools'
                    src={
                      router.asPath.includes('/image-generator')
                        ? '/assets/imageGen-blue.svg'
                        : theme === 'dark'
                        ? '/assets/imageGen-white.svg'
                        : '/assets/imageGen-black.svg'
                    }
                    className=''
                    width={24}
                    height={20}
                  />
                </div>
                <Typography
                  className={
                    router.asPath === '/image-generator'
                      ? 'text-secondary-blue  pt-1'
                      : 'dark:text-white cursor-pointer text-custom-font-color  pt-1'
                  }
                  fontWeight='bold'
                >
                  Images
                </Typography>
              </Grid>
            </Link>
          </Grid>
        </Box>
      </Drawer>
    </MobileWrapper>
  );
}
