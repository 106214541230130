import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { setDrawerOption } from '@redux/actions/general';

import { captureOutboundLink } from '@services/analytics';
import apiConfig from '@config/api.service';

const ItemsList = dynamic(() => import('./items.list'), {
  ssr: false,
});

const drawerWidth = 256;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(9)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeaderWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  zIndex: 999,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

const Sidebar = ({ closable = false, inEditor = false, defaultClosed }) => {
  const theme = useTheme();
  const { INKFORALLURL } = apiConfig;
  const dispatch = useDispatch();
  const router = useRouter();

  const {
    general: { isDrawerOpen },
  } = useSelector((gState) => gState);

  const _isDrawerOpen = defaultClosed ? false : isDrawerOpen;

  // event handlers
  const clickHandler = (event) => {
    captureOutboundLink(event, `${INKFORALLURL}`);
    router.push('/');
  };

  return (
    <Drawer
      elevation={0}
      className='bg-white text-black dark:bg-header-black dark:text-white'
      variant='permanent'
      open={_isDrawerOpen}
      PaperProps={{ sx: { border: 'none' } }}
    >
      {!inEditor && (
        <DrawerHeaderWrapper className='bg-white text-black dark:bg-header-black dark:text-white '>
          <Box
            display='flex'
            justifyContent='space-between'
            width='100%'
            alignItems='center'
            className=' pt-2 pl-2'
          >
            <Image
              alt='INK'
              layout='fixed'
              width='40px'
              height='40px'
              src='/assets/ink.svg'
              className='rounded-md cursor-pointer'
              onClick={clickHandler}
            />

            {closable && (
              <IconButton
                aria-label='chevron'
                onClick={() => dispatch(setDrawerOption(false))}
              >
                {theme?.direction === 'rtl' ? (
                  <ChevronRightIcon className='dark:text-white' />
                ) : (
                  <ChevronLeftIcon className='dark:text-white' />
                )}
              </IconButton>
            )}
          </Box>
        </DrawerHeaderWrapper>
      )}

      {/* ALL DRAWER LIST ITEMS */}
      <ItemsList open={_isDrawerOpen} inEditor={inEditor} />
    </Drawer>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  closable: PropTypes.bool,
  inEditor: PropTypes.bool,
  defaultClosed: PropTypes.bool,
};
