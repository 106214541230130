import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import { IsMobileWidth } from '@components/util/MediaQueryUtils';
import ContentDialog from '@components/custom.dialog/custom.dialog';
import { useDispatch, useSelector } from 'react-redux';
import { captureOutboundLink } from '@services/analytics';
import apiConfig from '@config/api.service';
// import InkPoints from '@components/InkPoints/InkPoints';
import AppHeader from '@library/components/Molecules/AppHeader/AppHeader';
import { setInkPointModalOpen } from '@redux/actions/general';
import GradientText from '@library/components/Atoms/UI/GradientText';
import Tabs from '@library/components/Atoms/Tabs/Tabs';
import Sidebar from '@library/components/Molecules/Sidebar/Sidebar';
import MobileBottomMenu from './components/mobile.menu/mobile.menu';

const DrawerHeaderWrapperDiv = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Wrapper = styled('div')`
  .css-1t29gy6-MuiToolbar-root {
    padding-left: 24px !important;
    padding-right: 16px !important;
  }
  .css-1u2mxbp {
    display: ${(props) => (props?.implicitlogout ? 'none' : 'block')};
  }
  .font-weight-custom {
    font-weight: bold;
  }
  .custom-bg-white {
    background-color: white;
  }
  .custom-border {
    border: 1px solid #ebebef;
  }
  .custom-dborder {
    border: 1px solid #31313f;
  }

  background-color: #fafafb;

  .btn-outlined-pink {
    border: 1px solid #f1f1f4;
    border-radius: 5px;
    color: #e45c96;
    background-color: white;
    font-size: 16px;
    padding: 6px 10px;
  }
  .btn-outlined-dark-pink {
    border: 1px solid #31313f;
    border-radius: 5px;
    background-color: #071116;
    font-weight: bold;
    color: #e45c96;
    padding: 6px 10px;
    font-size: 16px;
  }
  .btn-outlined-dark-pink:hover {
    border: 1px solid #e45c96;
  }
  .btn-outlined-pink:hover {
    border: 1px solid #e45c96;
    border-radius: 5px;
    color: #e45c96;
    background-color: white;
  }
  .btn-custom-pink {
    background-color: #e45c96;
    color: white;
    text-transform: capitalize;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    padding: 11px 30px;
  }
  .btn-custom-pink:hover {
    background-color: #cd5387;
    color: white;
  }
  .btn-custom-blue {
    background-color: #2499e7;
    color: white;
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    font-weight: 600;
    padding: 10px 25px;
  }
  .btn-custom-blue:hover {
    background-color: #208ad0;
    color: white;
  }
  color: #505876;
  .unselected-tab-button {
    color: #505876;
    border-radius: 25px;
    border: 1px solid #e45c96;
    padding: 1px 14px;
    text-transform: capitalize;
    font-size: 13px;
  }
  .tab-button-selected {
    background-color: #e45c96;
    color: white;
    border-radius: 25px;
    border: 1px solid #e45c96;
    padding: 1px 14px;
    text-transform: capitalize;
    font-size: 13px;
  }
  .tab-button-selected:hover {
    background-color: #e45c96;
    color: white;
    border-radius: 25px;
    border: 1px solid #e45c96;
    padding: 1px 14px;
    text-transform: capitalize;
    font-size: 13px;
  }
  .dark-tab-button {
    background-color: #252530;
    color: white;
    border-radius: 25px;
    border: 2px solid #31313f;
    padding: 1px 14px;
    text-transform: capitalize;
    font-size: 13px;
  }
  .dark-tab-button:hover {
    // background-color: white;
    // color: #505876;
    font-size: 13px;
  }
  .tab-button {
    background-color: white;
    color: #505876;
    border-radius: 25px;
    border: 1px solid #ebebef;
    padding: 1px 14px;
    text-transform: capitalize;
    font-size: 13px;
  }
  .tab-button:hover {
    background-color: white;
    color: #505876;
    border-radius: 25px;
    border: 1px solid #e45c96;
    padding: 1px 14px;
    text-transform: capitalize;
    font-size: 13px;
  }

  .default-outlined-btn {
    border: 1px solid #ebebef;
    border-radius: 5px;
    color: #505876;
    background-color: white;
    padding: 8px 10px;
  }
  .default-outlined-btn:hover {
    color: #505876;
    background: #ebebef;
  }
  .MuiDrawer-paper {
    border: none;
  }
  font-family: 'Montserrat', sans-serif !important;
  .MuiTypography-root {
    font-family: 'Montserrat', sans-serif !important;
  }
  .MuiTableCell-root {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 14px !important;
  }
  .MuiTableCell-head {
    font-family: 'Montserrat', sans-serif !important;
    font-size: 12px !important;
  }
  .MuiButton-root {
    font-family: 'Montserrat', sans-serif !important;
    text-transform: capitalize !important;
  }
  .css-cveggr-MuiListItemIcon-root {
    min-width: 32px !important;
  }
  .css-10hburv-MuiTypography-root {
    font-size: 14px !important;
    letter-spacing: 0.1em !important;
  }
`;

export default function Main({
  children,
  searchBox = {},
  title,
  tabs,
  closable = false,
  forceCloseSidebar,
}) {
  const {
    user: { loggedUser },
    general: { inkPointModalOpen, implicitLogout },
  } = useSelector((gState) => gState);

  // const [open, setOpen] = React.useState(drawerOpen);
  const dispatch = useDispatch();

  // ! TEMPORARY: doc migration
  React.useEffect(() => {
    if (loggedUser?.documentMigration === 'done') {
      localStorage.setItem('isDocFromNewStorage', true);
    } else {
      localStorage.setItem('isDocFromNewStorage', false);
    }
  }, [loggedUser]);

  const onClose = () => {
    dispatch(setInkPointModalOpen());
  };

  const mobileView = IsMobileWidth();
  return (
    <Wrapper implicitlogout={implicitLogout}>
      <div className='dark:bg-custom-black'>
        <ContentDialog
          dialogIsOpen={inkPointModalOpen}
          onClose={onClose}
          feature='AI-Tools'
          planName={loggedUser?.userPlan?.name}
        />

        {/* SIDEBAR */}
        {!mobileView && (
          <Box sx={{ display: 'flex' }}>
            <CssBaseline />

            {/* APP HEADER */}
            {!implicitLogout && (
              <AppHeader
                searchBox={searchBox}
                closedSidebar={forceCloseSidebar}
              />
            )}
            {/* SIDEBAR */}
            {!implicitLogout && (
              <Sidebar closable={closable} defaultClosed={forceCloseSidebar} />
            )}
            <Box
              className='text-custom-font-color dark:text-white overflow-hidden'
              component='main'
              sx={{ flexGrow: 1, p: `${!implicitLogout ? '20px' : '0px'}` }}
              height={`${!implicitLogout && '100%'}`}
            >
              <DrawerHeaderWrapperDiv implicitLogout={implicitLogout} />

              {title && (
                <div className='flex'>
                  <GradientText variant='h1' fontWeight='700' fontSize='32px'>
                    {title}
                  </GradientText>
                </div>
              )}

              {tabs && <Tabs tabs={tabs} />}

              {children}
            </Box>
          </Box>
        )}

        {/* MOBILE BOTTOM BAR  (ONLY MOBILE VIEW) */}
        {mobileView && (
          <div>
            <Box
              component='main'
              sx={{
                flexGrow: 1,
                p: `${!implicitLogout ? '16px' : '0px'}`,
              }}
              height='100%'
              className='pb-10 mb-10 text-custom-font-color dark:text-white'
            >
              {title && (
                <GradientText variant='h1' fontWeight='700' fontSize='32px'>
                  {title}
                </GradientText>
              )}

              {tabs && <Tabs tabs={tabs} />}

              {children}
            </Box>
            {!implicitLogout && <MobileBottomMenu />}
          </div>
        )}
      </div>
    </Wrapper>
  );
}

Main.propTypes = {
  children: PropTypes.node,
  searchBox: PropTypes.shape({
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    Icon: PropTypes.any,
    show: PropTypes.bool,
    value: PropTypes.string,
  }),
  title: PropTypes.string,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      Icon: PropTypes.node,
    })
  ),
  closable: PropTypes.bool,
  forceCloseSidebar: PropTypes.bool,
};
