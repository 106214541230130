import { Button, Grid } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React from 'react';
import { useTheme } from 'next-themes';
import PropTypes from 'prop-types';

function Tabs({ tabs }) {
  const router = useRouter();
  const { theme } = useTheme();

  return (
    <Grid container spacing={2} className='pt-3'>
      {tabs.map((tab) => (
        <Grid item key={tab.name} alignItems='center'>
          <Link href={tab.path}>
            <Button
              className={
                router.asPath.includes(tab.path)
                  ? 'tab-button-selected'
                  : theme === 'dark'
                  ? 'dark-tab-button'
                  : 'tab-button'
              }
            >
              {tab?.Icon && tab.Icon}

              <span
                style={{
                  marginLeft: tab?.Icon ? '0.4rem' : '0',
                }}
              >
                {tab.name}
              </span>
            </Button>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
}

// types
Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      path: PropTypes.string.isRequired,
      Icon: PropTypes.node,
    })
  ).isRequired,
};

export default Tabs;
