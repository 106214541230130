import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTheme } from 'next-themes';
import MobileDrawer from '../mobile.drawer/mobile.drawer';

const MobileBottomMenu = () => {
  const router = useRouter();
  const appTheme = useTheme();

  return (
    <Box
      className='w-full bg-white text-black dark:bg-header-black dark:text-white dark:border-custom-border fixed'
      style={{ left: '0', bottom: '0' }}
      height='60px'
      borderTop='2px solid #EBEBEF'
      textAlign='center'
    >
      <Grid container className='px-1 py-2'>
        <Grid xs={4}>
          <MobileDrawer />
        </Grid>
        <Link href='/hey-ink'>
          <Grid xs={4} className='text-center cursor-pointer'>
            <div className='flex justify-center'>
              <img
                className='cursor-pointer'
                alt='tools'
                src={
                  router.asPath.includes('/hey-ink')
                    ? '/assets/heyink-blue.svg'
                    : appTheme?.theme === 'dark'
                    ? '/assets/heyink-white.svg'
                    : '/assets/heyink-black.svg'
                }
                width={24}
                height={24}
              />
            </div>
            <Typography
              className={
                router.asPath === '/hey-ink'
                  ? 'text-secondary-blue  pt-1'
                  : 'dark:text-white cursor-pointer text-custom-font-color  pt-1'
              }
              fontWeight='bold'
            >
              AI Assistant
            </Typography>
          </Grid>
        </Link>
        <Link href='/image-generator'>
          <Grid xs={4} className='text-center cursor-pointer'>
            <div
              style={
                {
                  // paddingBottom: 4,
                }
              }
              className='flex justify-center'
            >
              <img
                alt='tools'
                src={
                  router.asPath.includes('/image-generator')
                    ? '/assets/imageGen-blue.svg'
                    : appTheme?.theme === 'dark'
                    ? '/assets/imageGen-white.svg'
                    : '/assets/imageGen-black.svg'
                }
                className=''
                width={24}
                height={20}
              />
            </div>
            <Typography
              className={
                router.asPath === '/image-generator'
                  ? 'text-secondary-blue  pt-1'
                  : 'dark:text-white cursor-pointer text-custom-font-color  pt-1'
              }
              fontWeight='bold'
            >
              Images
            </Typography>
          </Grid>
        </Link>
      </Grid>
    </Box>
  );
};

export default MobileBottomMenu;
