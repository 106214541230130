import React from 'react';
import PropTypes from 'prop-types';
import MuiAvatar from '@mui/material/Avatar';
import CustomSkeleton from '@components/Skeleton/Skeleton';

const Avatar = ({
  title = 'INK',
  picture,
  alt = 'avatar',
  handleClick,
  active = false,
  style = {},
}) => {
  function stringToColor(param = 'INK') {
    const string = param.replace("'s Team", '');
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(param = 'INK') {
    const name = param?.trim();
    let string;
    if (name.includes(' ')) {
      string = `${name.split(' ')[0][0]}${name.split(' ')?.[1][0]}`;
    } else {
      string = name.slice(0, 1);
    }
    return {
      sx: {
        bgcolor: stringToColor(name),
        outline: active ? '2px solid #E45C96' : '',
        cursor: 'pointer',
        ...style,
      },
      children: string?.toUpperCase(),
    };
  }
  if (!picture && !title) {
    return <CustomSkeleton variant='circular' width={40} height={40} />;
  }
  return picture ? (
    <MuiAvatar
      {...stringAvatar(title)}
      src={picture}
      alt={alt}
      onClick={handleClick}
    />
  ) : (
    <MuiAvatar {...stringAvatar(title)} alt={alt} onClick={handleClick} />
  );
};

Avatar.propTypes = {
  title: PropTypes.string,
  picture: PropTypes.string,
  alt: PropTypes.string,
  handleClick: PropTypes.func,
  active: PropTypes.bool,
  style: PropTypes.object,
};

export default Avatar;
