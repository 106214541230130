import * as React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Image from 'next/image';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Search from '@mui/icons-material/Search';
import TextField from '@components/Input/Input';
import CustomSkeleton from '@components/Skeleton/Skeleton';
import { setDrawerOption, setSearchNotFound } from '@redux/actions/general';
import { memo, useEffect } from 'react';
import { Input, InputAdornment } from '@mui/material';
import { useTheme } from 'next-themes';
import ProfileBox from './ProfileBox';

const drawerWidth = 256;
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: 1000,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

//* THIS CODE IS SPLITTED FROM layouts/main.js (for beginning of refactoring process...)

function AppHeader({ searchBox, closedSidebar }) {
  const {
    user: { authStatus, selectedAvatarOption, loggedUser },
    general: { isDrawerOpen },
  } = useSelector((gState) => gState);
  // const [open, setOpen] = React.useState(drawerOpen);
  const [textField, setTextField] = React.useState('');
  const router = useRouter();
  const dispatch = useDispatch();
  const { theme } = useTheme();

  const handleDrawerOpen = () => {
    // setOpen(true);
    dispatch(setDrawerOption(true));
  };

  useEffect(() => {
    const tools = document.querySelector('.searchable-content')?.children;
    if (tools) {
      Array.from(tools)
        .filter(
          (element) =>
            !element.textContent
              .toLowerCase()
              .includes(textField.toLowerCase().trim())
        )
        .forEach((element) => element.classList.add('hidden'));
      Array.from(tools)
        .filter((element) =>
          element.textContent
            .toLowerCase()
            .includes(textField.toLowerCase().trim())
        )
        .forEach((element) => element.classList.remove('hidden'));

      const filteredArray = Array.from(tools).filter(
        (element) => !element.classList.contains('hidden')
      );

      if (!filteredArray.length) {
        dispatch(setSearchNotFound(true));
      } else {
        dispatch(setSearchNotFound(false));
      }
    }
  }, [textField, router, dispatch]);

  return (
    <AppBar
      position='fixed'
      open={isDrawerOpen}
      elevation={0}
      sx={{
        zIndex: 1000,
        width: `calc(100% - ${
          isDrawerOpen && !closedSidebar ? drawerWidth : '60'
        }px) !important`,
      }}
    >
      <div className='bg-white text-black dark:bg-header-black dark:text-white w-full h-full'>
        <Toolbar
          style={{
            paddingLeft: '24px',
            paddingRight: '16px',
          }}
        >
          <IconButton
            id='button-open-menu'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            className='dark:text-white'
            sx={{
              display: isDrawerOpen ? 'none' : '',
              // marginLeft: '-6px',
              marginLeft: !isDrawerOpen ? '-16px' : '',
            }}
          >
            {/* <MenuIcon /> */}
            <Image
              alt='burger'
              layout='fixed'
              width='40px'
              height='40px'
              src={
                theme === 'dark' ? '/assets/menu-white.svg' : '/assets/menu.svg'
              }
              className='rounded-md cursor-pointer'
            />
          </IconButton>
          <Grid container alignItems='center' justifyContent='space-between'>
            <Grid className={`${isDrawerOpen ? '' : 'ml-11'}`}>
              {searchBox?.show ? (
                <Box>
                  <TextField
                    style={{ background: 'transparent' }}
                    size='small'
                    icon={{
                      position: 'start',
                      svg: searchBox.Icon ? (
                        <searchBox.Icon />
                      ) : (
                        <Search className='dark:text-white' />
                      ),
                    }}
                    borderLess
                    value={searchBox?.value ?? textField}
                    getValue={(e) => {
                      searchBox?.onChange?.(e);

                      setTextField(e.target.value);
                    }}
                    placeholder={searchBox?.placeholder ?? 'Search...'}
                  />
                </Box>
              ) : null}
            </Grid>

            {authStatus === 'deciding' ? (
              /* provided item to hide a warning message */
              <Grid xs={12} container justifyContent='flex-end' item>
                <CustomSkeleton
                  style={{ marginRight: '17px', marginTop: '4px' }}
                  variant='rectangular'
                  width={70}
                  height={32}
                />
                <CustomSkeleton variant='circular' width={40} height={40} />
              </Grid>
            ) : null}
            {authStatus !== 'undecided' &&
            authStatus !== 'deciding' &&
            router?.pathname !== '/login' ? (
              <Grid>
                <Grid>
                  <Grid container alignItems='center'>
                    <div>
                      <ProfileBox
                        selectedAvatarOption={selectedAvatarOption}
                        loggedUser={loggedUser}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            ) : null}
          </Grid>
        </Toolbar>
      </div>
    </AppBar>
  );
}

export default memo(AppHeader);

AppHeader.propTypes = {
  searchBox: PropTypes.shape({
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    Icon: PropTypes.any,
    show: PropTypes.bool,
    value: PropTypes.string,
  }),
  closedSidebar: PropTypes.bool,
};
