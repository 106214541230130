import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import PeopleIcon from '@mui/icons-material/People';
import HelpCenter from '@mui/icons-material/HelpCenter';
import { SvgIcon, Typography } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import Link from 'next/link';
import { logout } from '@redux/services/user';
import { useDispatch } from 'react-redux';
import Avatar from '@components/Avatar/Avatar';
import { useTheme } from 'next-themes';
import PublicIcon from '@mui/icons-material/Public';
import { captureManualEvent } from '@services/analytics';
import posthog from 'posthog-js';
// import { makeStyles } from '@mui/styles';
import moment from 'moment';
import { userDetails } from '@helpers/utils';
import Image from 'next/image';

// TODO: if this file not used then remove it as we have another one - components/layouts/main/components/profile.box/profile.box.js

const ImageContainer = styled('div')(`
  display: flex;
  margin-right: 8px;
  & .image-dark {
    filter: brightness(0) invert(1);
  }
`);

export default function ProfileBox({ selectedAvatarOption, loggedUser }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  // const useStyles = makeStyles({
  //   iconRoot: {
  //     minWidth: '32px',
  //   },
  //   typoRoot: {
  //     fontSize: '14px',
  //     letterSpacing: '0.1em',
  //   },
  // });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    const user = userDetails(loggedUser);
    const obj = {
      teamId:
        selectedAvatarOption?.accountType !== 'personal'
          ? selectedAvatarOption?._id
          : null,
      category: 'click',
      label: document?.title,
      trialStart: loggedUser?.trialInfo?.startedOn
        ? moment(loggedUser?.trialInfo?.expiry).unix() > moment().unix()
          ? moment(loggedUser?.trialInfo?.startedOn).unix()
          : null
        : null,
      trialEnd: loggedUser?.trialInfo?.expiry
        ? moment(loggedUser?.trialInfo?.expiry).unix() > moment().unix()
          ? moment(loggedUser?.trialInfo?.expiry).unix()
          : null
        : null,
      action: 'open-user-dropdown',
    };
    captureManualEvent(obj);
    posthog.capture('user-signup', {
      userDetails: user,
      ...obj,
    });
  };
  const handleClose = (e) => {
    let action = '';
    const text = e.target.textContent.toLowerCase();
    switch (text) {
      case 'account settings':
        action = 'open-account-details';
        break;
      case 'add members':
        action = 'open-add-members';
        break;
      case 'sign out':
        action = 'logout-account';
        break;
      default:
        break;
    }
    captureManualEvent({
      teamId:
        selectedAvatarOption?.accountType !== 'personal'
          ? selectedAvatarOption?._id
          : null,
      category: 'click',
      trialStart: loggedUser?.trialInfo?.startedOn
        ? moment(loggedUser?.trialInfo?.expiry).unix() > moment().unix()
          ? moment(loggedUser?.trialInfo?.startedOn).unix()
          : null
        : null,
      trialEnd: loggedUser?.trialInfo?.expiry
        ? moment(loggedUser?.trialInfo?.expiry).unix() > moment().unix()
          ? moment(loggedUser?.trialInfo?.expiry).unix()
          : null
        : null,
      label: selectedAvatarOption?.title
        ? selectedAvatarOption?.title
        : selectedAvatarOption?.accountType,
      action,
    });
    setAnchorEl(null);
  };

  const handleLogout = (e) => {
    handleClose(e);
    dispatch(logout());
    setAnchorEl(null);
  };
  const { theme } = useTheme();
  const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(() => ({
    '& .menuEnd': {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    '& .commonText': {
      fontFamily: `'Montserrat', sans-serif`,
    },
    '& .MuiTypography-root': {
      fontFamily: `'Montserrat', sans-serif`,
    },
    '& .MuiPaper-root': {
      borderRadius: 6,
      border: theme === 'light' ? '1px solid #EBEBEF' : '1px solid #666',
      marginTop: '5px',
      minWidth: 280,
      // color:
      //   theme.palette.mode === 'light'
      //     ? 'rgb(55, 65, 81)'
      //     : theme.palette.grey[300],
      // boxShadow:
      //   'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '0px',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          // color: theme.palette.text.secondary,
          marginRight: '12px',
        },
        '&:active': {
          // backgroundColor: alpha(),
          // theme.palette.primary.main,
          // theme.palette.action.selectedOpacity
        },
      },
    },
  }));
  return (
    <div>
      {selectedAvatarOption?.accountType === 'personal-team' ? (
        <Avatar
          title={selectedAvatarOption?.title}
          picture={selectedAvatarOption?.picture}
          alt='team'
          handleClick={handleClick}
        />
      ) : (
        <Avatar
          picture={selectedAvatarOption?.picture}
          title={selectedAvatarOption?.title}
          alt='team'
          handleClick={handleClick}
        />
      )}

      <StyledMenu
        id='demo-customized-menu'
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <div className='dark:bg-header-black dark:text-white h-full py-2'>
          <MenuItem className='dark:bg-header-black dark:text-white'>
            <div className='flex items-start'>
              <Avatar title={selectedAvatarOption?.title} alt='team' />

              <div className='pl-2'>
                <Typography
                  variant='body2'
                  className='dark:text-white font-weight-custom text-custom-font-color capitalize'
                >
                  <b>{loggedUser?.name}</b>
                </Typography>

                {selectedAvatarOption?.accountType !== 'team' ? (
                  <div className='text-xs commonText'>{loggedUser?.email}</div>
                ) : (
                  <div className='text-xs commonText'>member</div>
                )}
              </div>
            </div>
          </MenuItem>

          <Divider
            sx={{
              my: 0.5,
              backgroundColor: theme === 'dark' ? 'white' : '',
            }}
          />
          <Link id='link-navigate-account' href='/account'>
            <MenuItem onClick={handleClose} disableRipple className='pt-2'>
              {/* Setting Icon goes here */}
              {/* <SettingsIcon
                className='text-custom-font-color'
                style={theme === 'dark' ? { color: 'white' } : null}
              /> */}
              {/* <SvgIcon
                className='text-custom-font-color'
                style={theme === 'dark' ? { color: 'white' } : null}
              > */}
              {/* <SettingsIcon /> */}
              {/* </SvgIcon> */}
              <ImageContainer>
                <Image
                  src='/assets/dropdown_icons/setting.svg'
                  alt='ink'
                  width='24px'
                  height='24px'
                  className={theme === 'dark' ? 'image-dark' : null}
                />
              </ImageContainer>
              <span className='text-sm text-custom-font-color dark:text-white commonText'>
                Account settings
              </span>
            </MenuItem>
          </Link>
          <a
            id='anchorTag-open-help-center'
            href='https://help.inkforall.com/'
            target='_blank'
            rel='noreferrer'
          >
            <MenuItem onClick={handleClose} disableRipple>
              <ImageContainer>
                <Image
                  src='/assets/dropdown_icons/question-mark.svg'
                  alt='ink'
                  width='24px'
                  height='24px'
                  className={theme === 'dark' ? 'image-dark' : null}
                />
              </ImageContainer>
              <span className='text-sm text-custom-font-color dark:text-white commonText'>
                Help Center
              </span>
            </MenuItem>
          </a>

          <a
            id='anchorTag-open-public-site-ink'
            href='https://inkforall.com/'
            target='_blank'
            rel='noreferrer'
          >
            <MenuItem onClick={handleClose} disableRipple>
              <ImageContainer>
                <Image
                  src='/assets/dropdown_icons/visit-website.svg'
                  alt='ink'
                  width='24px'
                  height='24px'
                  className={theme === 'dark' ? 'image-dark' : null}
                />
              </ImageContainer>
              <span className='text-sm text-custom-font-color dark:text-white commonText'>
                Visit Our Website
              </span>
            </MenuItem>
          </a>
          <Divider
            sx={{ my: 0.5, backgroundColor: theme === 'dark' ? '#666' : '' }}
          />
          <MenuItem
            onClick={handleLogout}
            disableRipple
            classes={{ root: 'menuEnd' }}
            className='w-100'
          >
            <Typography
              className='flex'
              id='Menu-signout'
              sx={{ fontSize: '12px' }}
            >
              <ImageContainer>
                <Image
                  src='/assets/dropdown_icons/logout.svg'
                  alt='ink'
                  width='16px'
                  height='16px'
                  className={theme === 'dark' ? 'image-dark' : null}
                />
              </ImageContainer>
              Sign out
            </Typography>
          </MenuItem>
        </div>
      </StyledMenu>
    </div>
  );
}
ProfileBox.propTypes = {
  selectedAvatarOption: PropTypes.object,
  loggedUser: PropTypes.object,
};
